<template>
	<TransitionGroup
		name="notification"
		tag="ul"
		class="fixed right-5 z-[100] mt-5 flex h-fit flex-col gap-2"
	>
		<DashboardNotification
			v-for="notification in NotificationStore.state.notifications"
			:key="notification.id"
			:notification="notification"
			@close="NotificationStore.removeNotification(notification.id)"
		/>
	</TransitionGroup>
</template>

<script setup lang="ts">
import DashboardNotification from './DashboardNotification.vue';
import { useNotificationStore } from '../../../stores/notificationStore';

const NotificationStore = useNotificationStore();
</script>

<style>
.notification-enter-active,
.notification-leave-active {
	transition: all 0.3s ease-in-out;
}
.notification-enter-from,
.notification-leave-to {
	opacity: 0.5;
	transform: translateX(200px);
}
</style>
